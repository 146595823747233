/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import { mediaQueries } from "../../../styles/breakpoints";
import SectionHeader from "../InteractiveTrailer/SectionHeader";
import InquiryForm from "./InquiryForm";
import { useAtomValue } from "jotai";
import { userInitiatedContactAtom } from "../../../data/state";
import { useEffect } from "react";

type ClientInquiryProps = {
  title: string;
  subtitle: string;
  backgroundImage: string;
};

export default function ClientInquiry({
  title,
  subtitle,
  backgroundImage,
}: ClientInquiryProps) {
  return (
    <div
      id="client-inquiry"
      key="centeringDiv"
      css={{
        paddingLeft: 35,
        paddingRight: 35,
        paddingTop: 100,
        paddingBottom: 100,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        borderTopColor: "rgb(240, 240, 240)",
        borderTopStyle: "solid",
        borderTopWidth: 20,
        boxSizing: "border-box",
        [mediaQueries[1]]: {
          paddingLeft: 125,
          paddingRight: 125,
        },
        [mediaQueries[1]]: {
          paddingTop: 150,
          paddingBottom: 150,
        },
      }}>
      <img
        alt="intertwining boxes"
        src={backgroundImage}
        css={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          top: 0,
          left: 0,
          bottom: 0,
          opacity: 1,
          filter: "blur(5px) brightness(.6)",
          zIndex: 0,
        }}
      />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
          boxSizing: "border-box",
          zIndex: 100,
          [mediaQueries[2]]: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: 1000,
          },
          [mediaQueries[3]]: {
            maxWidth: 1280,
          },
        }}>
        <SectionHeader
          textColor={"white"}
          primaryText={title}
          secondaryText={subtitle}
          containerStyles={css({ flex: 1, margin: 0, padding: 0 })}
          primaryTextStyles={css({
            [mediaQueries[2]]: {
              width: 450,
            },
            [mediaQueries[3]]: {
              width: 525,
            },
          })}
        />
        <InquiryForm />
      </div>
    </div>
  );
}
