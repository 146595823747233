/** @jsxImportSource @emotion/react */

import { mediaQueries } from "../../../styles/breakpoints";
import { FFOrange } from "../../../styles/colors";
import ClientInquiry from "../ClientInquiry";
import ClientPortal from "../ClientPortal";
import InteractiveExperiences from "./InteractiveExperiences";
import TraditionalTrailers from "./TraditionalTrailers";

// Assets
import jurassicWorld from "./jurassicWorld.jpeg";

export default function InteractiveTrailer() {
  return (
    <div css={{ overflow: "hidden", backgroundColor: "#101020" }}>
      <TraditionalTrailers />
      <InteractiveExperiences />
      <ClientPortal
        backgroundColor={"#101020"}
        textColor={"white"}
        borderColor={FFOrange}
        product="default">
        <p
          css={{
            fontFamily: "Inter",
            fontSize: 14,
            fontWeight: 300,
            margin: 0,
            marginTop: 25,
            [mediaQueries[1]]: {},
            [mediaQueries[3]]: { fontSize: 18 },
          }}>
          As viewers explore your interactive trailer, their choices are
          gathered along with a wide array of data points about what is working
          (and what isn't) in marketing your film.
        </p>
        <p
          css={{
            fontFamily: "Inter",

            fontSize: 14,
            fontWeight: 300,
            margin: 0,
            marginTop: 15,
            [mediaQueries[1]]: {},
            [mediaQueries[3]]: { fontSize: 18 },
          }}>
          Then we make it all available to you in our client portal. Where is
          your film trending? What about your film are viewers most interested
          in? How many ticket sales are coming from your trailer?
        </p>
        <p
          css={{
            fontFamily: "Inter",
            fontSize: 14,
            fontWeight: 700,
            margin: 0,
            marginTop: 15,
            textTransform: "uppercase",
            [mediaQueries[1]]: {},
            [mediaQueries[3]]: { fontSize: 18 },
          }}>
          You'll have it all.
        </p>
      </ClientPortal>
      <ClientInquiry
        title="START BUILDING YOUR INTERACTIVE TRAILER TODAY"
        subtitle="WE'RE READY TO HELP YOU"
        backgroundImage={jurassicWorld}
      />
    </div>
  );
}
