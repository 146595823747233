/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from "@emotion/react";
import { CSSProperties, useMemo, useRef, useState } from "react";

type BasicButtonProps = {
  text: string;
  onClick: () => void;
  containerStyles?: SerializedStyles;
  colorSpec: {
    backgroundColor: CSSProperties["color"];
    textColor: CSSProperties["color"];
    hoverBackgroundColor?: CSSProperties["color"];
    hoverTextColor?: CSSProperties["color"];
  };
  disabled?: boolean;
};

export default function BasicButton({
  text,
  onClick,
  containerStyles = css(),
  colorSpec,
  disabled = false,
}: BasicButtonProps) {
  return (
    <button
      onClick={onClick}
      css={[
        {
          boxSizing: "border-box",
          color: colorSpec.textColor,
          backgroundColor: colorSpec.backgroundColor,
          cursor: "pointer",
          fontFamily: "Inter",
          fontSize: 12,
          fontWeight: 700,
          letterSpacing: "1px",
          opacity: 1,
          padding: 10,
          pointerEvents: "auto",
          textTransform: "uppercase",
          border: "none",
          transition: "all .25s ease",
          "&:hover": {
            color: colorSpec.hoverTextColor ?? colorSpec.textColor,
            backgroundColor:
              colorSpec.hoverBackgroundColor ?? colorSpec.backgroundColor,
          },
        },
        containerStyles,
      ]}
      disabled={disabled}>
      {text}
    </button>
  );
}
