/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import { mediaQueries } from "../../../styles/breakpoints";
import { FFDarkGray } from "../../../styles/colors";
import SectionHeader from "./SectionHeader";
import tickets from "../assets/tickets.png";
import increasedEngagement from "../assets/16x.png";
import increasedAction from "../assets/7x.png";

export default function InteractiveExperiences() {
  return (
    <div
      key="centeringDiv"
      css={{
        position: "relative",
        backgroundColor: "#E8E6E3",
        paddingLeft: 35,
        paddingRight: 35,
        paddingTop: 50,
        paddingBottom: 75,
        display: "flex",
        justifyContent: "center",
        [mediaQueries[1]]: {
          paddingLeft: 125,
          paddingRight: 125,
        },
        [mediaQueries[3]]: {
          paddingTop: 75,
        },
      }}>
      <img
        alt="movie-tickets"
        src={tickets}
        css={{
          position: "absolute",
          top: 0,
          right: "5%",
          bottom: 0,
          height: "100%",
        }}
      />
      <div
        id="interactive-experiences-content"
        css={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          [mediaQueries[2]]: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: 1000,
          },
          [mediaQueries[3]]: {
            width: 1280,
          },
        }}>
        <SectionHeader
          textColor={FFDarkGray}
          primaryText="DRAMATICALLY INCREASE
AUDIENCE ENGAGEMENT"
          secondaryText="INTERACTIVE EXPERIENCES"
          containerStyles={css({
            [mediaQueries[2]]: {
              maxWidth: 375,
            },
            [mediaQueries[2]]: {
              maxWidth: 450,
            },
          })}
          secondaryTextStyles={css({
            [mediaQueries[2]]: {
              maxWidth: 200,
            },
          })}
        />
        <div
          id="graphs"
          css={{
            display: "flex",
            flexDirection: "column",
            [mediaQueries[0]]: {
              flexDirection: "row",
              alignItems: "flex-end",
            },
          }}>
          <img
            alt="16x content engagement"
            src={increasedEngagement}
            css={{
              width: 175,
              marginTop: 50,
              marginRight: 50,
              [mediaQueries[2]]: {
                width: 200,
                marginTop: 0,
              },
              [mediaQueries[3]]: {
                width: 250,
              },
            }}
          />
          <img
            alt="7x content engagement"
            src={increasedAction}
            css={{
              width: 175,
              marginTop: 25,
              [mediaQueries[2]]: {
                width: 200,
                marginTop: 0,
              },
              [mediaQueries[3]]: {
                width: 250,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
