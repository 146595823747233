/** @jsxImportSource @emotion/react */

import { useSpring, animated, config } from "@react-spring/web";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { currentFeatureAtom } from "../data/general";
import { currentLinearProjectAtom, linearProjectsAtom } from "../data/state";

export default function Matte() {
  const currentFeature = useRecoilValue(currentFeatureAtom);
  const currentLinearProject = useRecoilValue(currentLinearProjectAtom);
  const linearProjects = useRecoilValue(linearProjectsAtom);

  const [layer1Image, setLayer1Image] = useState<string | undefined>(undefined);
  const [layer2Image, setLayer2Image] = useState<string | undefined>(undefined);

  const [layer1Styles, layer1Animation] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  const [layer2Styles, layer2Animation] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  useEffect(() => {
    if (currentFeature === "Linear" && linearProjects.length) {
      const newProjectImage =
        linearProjects[currentLinearProject].data.poster_frame.url;

      if (newProjectImage === layer1Image) return;
      console.log(newProjectImage, layer1Image);

      if (layer1Image) {
        layer1Animation.start({ opacity: 0, immediate: true });
      }

      setLayer1Image(newProjectImage);
      layer1Animation.start({
        to: { opacity: 1 },
        config: { clamp: true, precision: 0.001 },
        onRest: () => {
          setLayer2Image(newProjectImage);
        },
      });
    }
  }, [
    currentFeature,
    linearProjects,
    currentLinearProject,
    layer1Animation,
    layer2Animation,
    layer1Image,
  ]);

  return (
    <>
      <animated.div
        style={layer1Styles}
        id="dynamicBackgroundLayer1"
        css={{
          backgroundImage: `url(${layer1Image})`,
          backgroundSize: "cover",
          filter: "blur(50px)",
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -8,
        }}
      />
      <animated.div
        id="dynamicBackgroundLayer2"
        css={{
          backgroundImage: `url(${layer2Image})`,
          backgroundSize: "cover",
          filter: "blur(50px)",
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -9,
        }}
      />
      <div
        id="dynamicBackgroundLayer3"
        css={{
          background: "black",
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -10,
        }}
      />
    </>
  );
}
