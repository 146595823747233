/** @jsxImportSource @emotion/react */

import { config, useSpring, animated } from "@react-spring/web";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { menuOpenAtom } from "../../data/menu";

type FilmReelProps = {
  videoURL: string;
};

export default function FilmReel({ videoURL }: FilmReelProps) {
  const isMenuOpen = useRecoilValue(menuOpenAtom);

  const [styles, api] = useSpring(() => ({
    from: { filter: "blur(20px)", opacity: 0, scale: 1.1 },
    to: { filter: "blur(0px)", opacity: 1, scale: 1.0 },
    delay: 1000,
    config: {
      ...config.default,
      mass: 300,
      clamp: true,
    },
  }));

  useEffect(() => {
    if (isMenuOpen === undefined) return;

    !isMenuOpen
      ? api.start({
          from: { filter: "blur(20px)" },
          to: { filter: "blur(0px)" },
          config: { mass: 5 },
        })
      : api.start({ filter: "blur(20px)", config: { mass: 5 } });
  }, [isMenuOpen, api]);

  return (
    <div
      css={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        zIndex: -10,
        overflow: "hidden",
      }}
    >
      <animated.video
        style={styles}
        src={videoURL}
        css={{
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        muted
        autoPlay
        loop
        playsInline
      />
    </div>
  );
}
