/** @jsxImportSource @emotion/react */

import { mediaQueries } from "../styles/breakpoints";

import { animated, config, useSpring } from "@react-spring/web";
import { FFDarkGray } from "../styles/colors";
import FFLogo from "./FFLogo";
import ClapboardMenu from "./widgets/ClapboardMenu";
import { useRecoilValue } from "recoil";
import { currentFeatureAtom } from "../data/general";

export default function TopGlobalNav() {
  const currentFeature = useRecoilValue(currentFeatureAtom);

  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1000,
    config: {
      ...config.default,
      mass: 300,
      clamp: true,
    },
  });

  return (
    <>
      <div
        id="smallScreenBackdrop"
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: 115,
          backgroundColor:
            currentFeature === "Home" ? "transparent" : FFDarkGray,
          transition: "all .5s ease",
          opacity: 0.5,
          zIndex: 90,
          [mediaQueries[0]]: {
            opacity: 0,
          },
        }}
      />
      <animated.div
        style={styles}
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: 115,
          zIndex: 125,
        }}
      >
        <div
          css={{
            position: "relative",
            paddingTop: 60,
            paddingLeft: 30,
            paddingRight: 30,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            boxSizing: "border-box",
            [mediaQueries[1]]: {
              paddingLeft: 50,
              paddingRight: 50,
            },
          }}
        >
          <FFLogo />
          <ClapboardMenu />
        </div>
      </animated.div>
    </>
  );
}
