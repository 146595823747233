/** @jsxImportSource @emotion/react */

import { css, SerializedStyles } from "@emotion/react";
import { useMemo, useRef, useState } from "react";

type OrangeUnderlineButtonProps = {
  text: string;
  onClick: () => void;
  containerStyles?: SerializedStyles;
  styleOverrides?: SerializedStyles;
};

export default function OrangeUnderlineButton({
  text,
  onClick,
  containerStyles = css(),
  styleOverrides = css(),
}: OrangeUnderlineButtonProps) {
  const [hover, setHover] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const height = useMemo(() => ref.current?.clientHeight ?? 0, [hover]);

  return (
    <div
      css={[{ position: "relative" }, containerStyles]}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <button
        ref={ref}
        css={[
          {
            boxSizing: "border-box",
            color: "rgb(255, 255, 255)",
            cursor: "pointer",
            fontFamily: "Gotham A, Gotham B",
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: "1.7px",
            opacity: 1,
            padding: 10,
            pointerEvents: "auto",
            textTransform: "uppercase",
            backgroundColor: "transparent",
            border: "none",
          },
          styleOverrides,
        ]}
      >
        {text}
      </button>
      <div
        css={{
          background: "#ffa441",
          boxShadow: "0 0 40px 2px #f38b3c",
          height: hover ? height + 10 : "5px",
          left: hover ? -10 : 0,
          bottom: -5,
          position: "absolute",
          transformOrigin: "bottom center",
          transition: "all .33s ease",
          width: "100%",
          zIndex: "-1",
          paddingLeft: hover ? 10 : 0,
          paddingRight: hover ? 10 : 0,
          // padding: hover ? 5 : 0,
        }}
      />
    </div>
  );
}
