/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useSinglePrismicDocument } from "@prismicio/react";
import { useSpring, animated, config } from "@react-spring/web";

import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentFeatureAtom } from "../../data/general";

import { menuOpenAtom } from "../../data/menu";
import CropMarks from "../CropMarks";

import OrangeUnderlineButton from "../widgets/OrangeUnderlineButton";
import FilmReel from "./FilmReel";
import Taglines from "./Taglines";

export default function Home() {
  const currentFeature = useRecoilValue(currentFeatureAtom);
  const [isMenuOpen, setMenuOpen] = useRecoilState(menuOpenAtom);
  const [document] = useSinglePrismicDocument("home");

  const [animationStyles, animation] = useSpring(() => ({}));

  const renderControls = () => (
    <OrangeUnderlineButton
      text="enter"
      containerStyles={css({
        position: "absolute",
        bottom: 60,
        zIndex: 10,
      })}
      onClick={() => setMenuOpen(!isMenuOpen)}
    />
  );

  useEffect(() => {
    currentFeature === "Home"
      ? animation.start({ to: { opacity: 1 }, config: config.molasses })
      : animation.start({ opacity: 0 });
  }, [currentFeature, animation]);

  return (
    <animated.div
      style={animationStyles}
      css={{
        display: "flex",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        overflow: "clip",
        pointerEvents: currentFeature === "Home" ? "auto" : "none",
      }}
    >
      <CropMarks />
      <FilmReel videoURL={document?.data.cover_video.url} />
      <Taglines text={document?.data.lead_text[0].text} />
      {renderControls()}
    </animated.div>
  );
}
