/** @jsxImportSource @emotion/react */

import { useSpring, animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Howl } from "howler";

import { currentFeatureAtom } from "../../data/general";
import { menuOpenAtom } from "../../data/menu";
import { vimeoVideoIDAtom } from "../../data/state";
import { FFOrange } from "../../styles/colors";
// @ts-ignore
import clapboardAudio from "../../assets/sounds/clapboard.wav";

const clapboardSound = new Howl({
  src: [clapboardAudio],
});

export default function ClapboardMenu() {
  const currentFeature = useRecoilValue(currentFeatureAtom);
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuOpenAtom);
  const vimeoID = useRecoilValue(vimeoVideoIDAtom);

  const [hover, setHover] = useState(false);

  const [theaterModeStyles, theaterModeAnimator] = useSpring(() => ({
    from: { opacity: 0 },
    // config: { friction: 12 },
  }));

  useEffect(() => {
    vimeoID || currentFeature === "Home"
      ? theaterModeAnimator.start({ opacity: 0 })
      : theaterModeAnimator.start({ opacity: 1 });
  }, [vimeoID, theaterModeAnimator, currentFeature]);

  return (
    <animated.button
      style={theaterModeStyles}
      css={{
        pointerEvents: currentFeature === "Home" || vimeoID ? "none" : "auto",
        position: "relative",
        zIndex: 125,
        background: "transparent",
        border: "none",
        outline: "none",
        display: "flex",
        alignItems: "center",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        setIsMenuOpen((previous) => !previous);
        clapboardSound.play();
      }}
    >
      <div css={{ display: "flex", flexDirection: "column", marginRight: 15 }}>
        <div
          css={{
            width: 13,
            height: 2,
            backgroundColor: isMenuOpen ? FFOrange : "white",
            transform: isMenuOpen ? "rotate(-30deg)" : "none",
            transition: "all .5s ease",
          }}
        />
        <div
          css={{
            width: 13,
            height: 2,
            backgroundColor: "white",
            marginTop: 2,
            transform:
              hover && !isMenuOpen
                ? "translateX(0px)"
                : isMenuOpen
                ? "translateX(0px) translateY(1px)"
                : "translateX(2px)",
            transition: "all .5s linear",
            transitionDelay: ".125s",
          }}
        />
        <div
          css={{
            width: 13,
            height: 2,
            backgroundColor: "white",
            marginTop: 2,
            transition: "all .5s ease",
            transitionDelay: ".125s",
          }}
        />
      </div>
      <span
        css={{
          fontSize: 13,
          fontWeight: 500,
          letterSpacing: 1.7,
          fontFamily: "Gotham A,Gotham B",
          color: "white",
        }}
      >
        MENU
      </span>
    </animated.button>
  );
}
