/** @jsxImportSource @emotion/react */
import { useAtom } from "jotai";

import {
  FFBlack,
  FFDarkGray,
  FFMidGray,
  FFOrange,
} from "../../../styles/colors";
import { mediaQueries } from "../../../styles/breakpoints";

// Components
import ClientInquiry from "../ClientInquiry";
import ClientPortal from "../ClientPortal";
import Intro from "./Intro";
import Highlights from "../Highlights";

// Assets
import gacPhoto from "./gacElementaryGroup.jpg";
import { userInitiatedContactAtom } from "../../../data/state";
import { useEffect } from "react";

export default function EducationalTours() {
  const [userInitiatedContact, setUserInitiatedContact] = useAtom(
    userInitiatedContactAtom
  );

  useEffect(() => {
    if (userInitiatedContact) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
      setUserInitiatedContact(false);
    }
  }, [userInitiatedContact, setUserInitiatedContact]);

  return (
    <div css={{ overflow: "hidden", backgroundColor: "#101020" }}>
      <Intro />
      <Highlights
        backgroundColor={"#E8E6E3"}
        textColor={FFDarkGray}
        borderColor={FFMidGray}
      />
      <ClientPortal
        backgroundColor={FFBlack}
        textColor={"white"}
        borderColor={FFOrange}
        product="schoolTour">
        <p
          css={{
            fontFamily: "Inter",
            fontSize: 14,
            fontWeight: 300,
            margin: 0,
            marginTop: 25,
            [mediaQueries[1]]: {},
            [mediaQueries[3]]: { fontSize: 18 },
          }}>
          Unlike a traditional school tour where you may get some basic data we
          track over 19 different event types that result in hundreds of data
          points for a typical engagement.
        </p>
        <p
          css={{
            fontFamily: "Inter",
            fontSize: 14,
            fontWeight: 300,
            margin: 0,
            marginTop: 15,
            [mediaQueries[1]]: {},
            [mediaQueries[3]]: { fontSize: 18 },
          }}>
          Then we make it all available in your customized client portal. What
          areas of your tour are most popular? Are there certain places that
          viewers tend to drop out? How many users are complete the custom goals
          you've created?
        </p>
        <p
          css={{
            fontFamily: "Inter",
            fontSize: 14,
            fontWeight: 700,
            margin: 0,
            marginTop: 15,
            textTransform: "uppercase",
            [mediaQueries[1]]: {},
            [mediaQueries[3]]: { fontSize: 18 },
          }}>
          You'll have all this and much more at your fingertips.
        </p>
      </ClientPortal>
      <ClientInquiry
        title="BUILD YOUR NEXT GENERATION CAMPUS EXPERIENCE"
        subtitle="WE'RE READY TO HELP YOU"
        backgroundImage={gacPhoto}
      />
    </div>
  );
}
