/** @jsxImportSource @emotion/react */

// window.addEventListener(
//   "message",
//   (event) => {
//     // Do we trust the sender of this message?  (might be
//     // different from what we originally opened, for example).
//     if (event.origin === "https://gac-drone-tour.vercel.app") {
//       console.log("FFI: Message received from experience", event.data);
//     }
//   },
//   false
// );

export default function InteractiveEmbedTest() {
  return (
    <div>
      <iframe
        title="interactive-trailer-demo"
        src="https://ff-ipt-git-dev-formfilms.vercel.app?enclosingURL=https://tacos.are.tasty.com/non-tour"
        allow="autoplay; fullscreen"
        allowFullScreen
        id="FFIP-iFrame"
        // width="100%"
        // height="100%"
        css={{
          border: "none",
          width: "100%",
          outline: "none",
          display: "block",

          "@media (orientation: landscape) or (min-width: 480px) ": {
            aspectRatio: "16/9",
            maxHeight: "100vh",
          },

          "@media (orientation: landscape) and (min-width: 1024px) ": {
            maxHeight: "unset",
          },

          "@media (orientation: portrait) and (width < 480px)": {
            aspectRatio: "9 / 16",

            // Not sure if you are able to do live calculations on screen dimensions with your
            // tech stack, but if you can, improve the layout even more through this
            // calculation.
            maxHeight:
              window.innerHeight < window.innerWidth * (9 / 16)
                ? "90vh"
                : "100vh",

            // If you can't do live/real-time calculations then you should
            // default to `maxHeight: 90vh` or similiar to prevent the
            // experience from becoming too tall on vertical orientations.
          },
        }}
      />

      <div
        css={{
          backgroundColor: "white",
          padding: 25,
          margin: 0,
          outline: 0,
          border: 0,
        }}>
        Other site content.
      </div>
    </div>
  );
}
