import { atom } from "recoil";

export const availableScreenDimensions = atom({
  key: "availableScreenDimensions",
  default: { width: 0, height: 0 },
});

export const currentFeatureAtom = atom<
  "Home" | "About" | "Linear" | "Interactive"
>({
  key: "currentWebsiteFeature",
  default: "Home",
});
