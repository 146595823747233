/** @jsxImportSource @emotion/react */

import { mediaQueries } from "../../styles/breakpoints";
import SectionHeader from "./InteractiveTrailer/SectionHeader";
import clientPortalScreenshots from "./assets/clientPortal.png";
import gacClientPortal from "./EducationalTours/gacClientNavigator.png"
import { CSSProperties, ReactNode } from "react";

type ClientPortalProps = {
  backgroundColor: CSSProperties["color"];
  textColor: CSSProperties["color"];
  borderColor: CSSProperties["color"];
  children: ReactNode;
  product?: 'default' | 'schoolTour'
};

export default function ClientPortal({
  backgroundColor,
  textColor,
  borderColor,
  children,
  product = 'default'
}: ClientPortalProps) {
  return (
    <div
      key="centeringDiv"
      css={{
        backgroundColor: backgroundColor,
        paddingLeft: 35,
        paddingRight: 35,
        paddingTop: 100,
        paddingBottom: 75,
        display: "flex",
        justifyContent: "center",
        borderTopColor: borderColor,
        borderTopStyle: "solid",
        borderTopWidth: 20,
        zIndex: 100,
        [mediaQueries[1]]: {
          paddingLeft: 125,
          paddingRight: 125,
        },
      }}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          [mediaQueries[2]]: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: 1000,
          },
          [mediaQueries[3]]: {
            width: 1280,
          },
        }}>
        <div
          id="client-portal-text"
          css={{
            color: textColor,
            [mediaQueries[1]]: {
              // maxWidth: 600,
            },
            [mediaQueries[2]]: {
              maxWidth: 375,
            },
            [mediaQueries[3]]: {
              maxWidth: 425,
            },
          }}>
          <SectionHeader
            textColor={textColor}
            primaryText="GIVES YOUR ADMISSIONS TEAM THE ADVANTAGE"
            secondaryText="OUR CLIENT PORTAL"
          />
          {children}
        </div>
        <img
          alt="client-portal-screenshots"
          src={product === 'schoolTour' ? gacClientPortal: clientPortalScreenshots}
          css={{
            position: "relative",
            objectFit: "contain",
            width: "100%",
            marginTop: 50,
            [mediaQueries[1]]: {
              maxWidth: 600,
            },
            [mediaQueries[2]]: {
              position: "absolute",
              zIndex: 100,
              right: 0,
              marginTop: 0,
              maxWidth: 600,
            },
            [mediaQueries[3]]: {
              maxWidth: 700,
            },
          }}
        />
      </div>
    </div>
  );
}
