import { atom as recoilAtom } from "recoil";
import { atom } from "jotai";

export const vimeoVideoIDAtom = recoilAtom<number | undefined>({
  key: "vimeoVideoID",
  default: undefined,
});

export const linearProjectsAtom = recoilAtom<any[]>({
  key: "linearProjects",
  default: [],
});

export const currentLinearProjectAtom = recoilAtom<number>({
  key: "currentLinearProject",
  default: 0,
});

export const userInitiatedContactAtom = atom(false);
