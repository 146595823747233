/** @jsxImportSource @emotion/react */

import { mediaQueries } from "../../styles/breakpoints";
import SectionHeader from "./InteractiveTrailer/SectionHeader";

import { CSSProperties } from "react";
import boxes from "../features/assets/boxes.png";
import { FFOrange } from "../../styles/colors";
import { css } from "@emotion/react";

type HighlightsProps = {
  backgroundColor: CSSProperties["color"];
  textColor: CSSProperties["color"];
  borderColor: CSSProperties["color"];
};

export default function Highlights({
  backgroundColor,
  textColor,
  borderColor,
}: HighlightsProps) {
  return (
    <div
      key="centeringDiv"
      css={{
        backgroundColor: backgroundColor,
        paddingLeft: 35,
        paddingRight: 35,
        paddingTop: 100,
        paddingBottom: 75,
        display: "flex",
        justifyContent: "center",
        borderTopColor: borderColor,
        borderTopStyle: "solid",
        borderTopWidth: 20,
        position: "relative",
        zIndex: 100,
        [mediaQueries[1]]: {
          paddingLeft: 125,
          paddingRight: 125,
        },
      }}>
      <img
        alt="intertwining boxes"
        src={boxes}
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          width: "100%",
          right: 0,
          height: "100%",
          opacity: 0.035,
          zIndex: 0,
          filter: "brightness(0)",
        }}
      />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          [mediaQueries[2]]: {
            width: 1000,
          },
          [mediaQueries[3]]: {
            width: 1280,
          },
        }}>
        <SectionHeader
          textColor={textColor}
          primaryText="DRIVES NEXT LEVEL ENGAGEMENT"
          secondaryText="THE ATLAS ENGINE"
          containerStyles={css({ maxWidth: 500 })}
        />

        <div
          id="callout-grid"
          css={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "1fr",
            columnGap: 50,
            rowGap: 10,
            fontFamily: "Inter",
            color: textColor,
            fontSize: 12,
            marginTop: 20,
            [mediaQueries[2]]: {
              gridTemplateRows: "1fr 1fr",
              gridTemplateColumns: "1fr 1fr",
              rowGap: 15,
            },
            [mediaQueries[3]]: { fontSize: 18 },
          }}>
          <div>
            <h3
              css={{
                color: FFOrange,
                textTransform: "uppercase",
                fontWeight: 600,
                marginBottom: 0,
                fontSize: "1.2em",
              }}>
              The only one of its kind
            </h3>
            <p
              css={{
                fontSize: 14,
                fontWeight: 300,
                margin: 0,
                color: textColor,
                [mediaQueries[2]]: { fontSize: 14 },
                [mediaQueries[3]]: { fontSize: 18 },
              }}>
              Our proprietary Atlas Engine interactivity platform humanizes
              online experiences like nothing else on the market. It creates the
              difference you need to stand out from the competition.
            </p>
          </div>
          <div>
            <h3
              css={{
                fontSize: "1.2em",
                color: FFOrange,
                textTransform: "uppercase",
                fontWeight: 600,
                marginBottom: 0,
              }}>
              Endlessly Updateable
            </h3>
            <p
              css={{
                fontSize: 14,
                fontWeight: 300,
                margin: 0,
                color: textColor,
                [mediaQueries[2]]: { fontSize: 14 },
                [mediaQueries[3]]: { fontSize: 18 },
              }}>
              Carefully scripted to be as evergreen as possible, each individual
              video node is independently updateable, making it simple to
              maintain a current and accurate experience with zero down time for
              your audience. This means fewer reshoots and lower costs.
            </p>
          </div>
          <div>
            <h3
              css={{
                fontSize: "1.2em",
                color: FFOrange,
                textTransform: "uppercase",
                fontWeight: 600,
                marginBottom: 0,
              }}>
              Increased Conversions
            </h3>
            <p
              css={{
                fontSize: 14,
                fontWeight: 300,
                margin: 0,
                color: textColor,
                [mediaQueries[2]]: { fontSize: 14 },
                [mediaQueries[3]]: { fontSize: 18 },
              }}>
              With greater engagement and increased clarity comes a higher rates
              of conversion.
            </p>
          </div>
          <div>
            <h3
              css={{
                color: FFOrange,
                textTransform: "uppercase",
                fontWeight: 600,
                marginBottom: 0,
                fontSize: "1.2em",
              }}>
              Higher Engagement
            </h3>
            <p
              css={{
                fontSize: 14,
                fontWeight: 300,
                margin: 0,
                color: textColor,
                [mediaQueries[2]]: { fontSize: 14 },
                [mediaQueries[3]]: { fontSize: 18 },
              }}>
              Interactive content has higher engagement than traditional,
              passive video.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
