/** @jsxImportSource @emotion/react */

import { config, useSpring, animated, easings } from "@react-spring/web";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { menuOpenAtom } from "../../data/menu";
import { mediaQueries } from "../../styles/breakpoints";

type TaglinesProps = {
  text: string;
};

export default function Taglines({ text }: TaglinesProps) {
  const isMenuOpen = useRecoilValue(menuOpenAtom);

  const [styles, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5, filter: "blur(20px)" },
    to: { opacity: 1, scale: 1, filter: "blur(0px)" },
    delay: 1500,
    config: {
      // ...config.default,
      duration: 2000,
      easing: easings.easeInSine,
      // clamp: true,
    },
  }));

  useEffect(() => {
    if (isMenuOpen === undefined) return;

    !isMenuOpen
      ? api.start({
          from: { opacity: 0, scale: 0.75, filter: "blur(20px)" },
          to: { opacity: 1, scale: 1, filter: "blur(0px)" },
          config: config.slow,
        })
      : api.start({
          opacity: 0,
          scale: 0.75,
          filter: "blur(20px)",
          config: config.molasses,
        });
  }, [isMenuOpen, api]);

  return (
    <animated.div
      style={styles}
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 190,

        [mediaQueries[1]]: {
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: 450,
        },
        [mediaQueries[3]]: {
          width: 650,
        },
      }}
    >
      <h1
        css={{
          opacity: 0.9,
          fontFamily: "Gotham A, Gotham B",
          textTransform: "uppercase",
          textShadow: "-2px 2px rgba(255,0,0,.5),2px -2px rgba(0,190,255,.2)",
          color: "white",
          fontWeight: 600,
          "& > span": {
            color: "#f38b3c",
          },
          letterSpacing: 2,
          fontSize: 38,
          lineHeight: "44px",
          marginTop: 0,
          marginLeft: 0,
          [mediaQueries[1]]: {
            marginRight: 50,
            marginBottom: 0,
          },
          [mediaQueries[3]]: {
            fontSize: 56,
            lineHeight: "60px",
            marginRight: 50,
          },
        }}
      >
        FORM
        <br />
        GREAT
        <br />
        <span>STORIES.</span>
      </h1>
      <p
        css={{
          fontFamily: "Whitney A, Whitney B",
          fontSize: 16,
          fontStyle: "italic",
          fontWeight: 500,
          lineHeight: "26px",

          color: "white",
          opacity: 0.8,
          margin: 0,
          [mediaQueries[1]]: {
            paddingBottom: ".6vh",
          },
          [mediaQueries[3]]: {
            fontSize: 19,
            lineHeight: "35px",
            maxWidth: 250,
          },
        }}
      >
        {text}
      </p>
    </animated.div>
  );
}
