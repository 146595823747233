/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import random from "lodash/random";

import { mediaQueries } from "../../../styles/breakpoints";
import { FFMidGray, FFOrange, FFOrangeMid } from "../../../styles/colors";
import { useAtomValue } from "jotai";
import { userInitiatedContactAtom } from "../../../data/state";
import BasicButton from "../../widgets/BasicButton";

const funnyMoneyPics = [
  "https://imgs.search.brave.com/jYbUAmmOBv1OYuczLvJjm5pWCr7BBj0XrJ7upiPbvyk/rs:fit:1200:720:1/g:ce/aHR0cHM6Ly9pbWFn/ZS50bWRiLm9yZy90/L3Avb3JpZ2luYWwv/ZEt6bTVyYnVUeEJP/MlBPdnpEQ2habzVy/c2piLmpwZw",
  "https://imgs.search.brave.com/Q6pBolIL9YP3asmCUg3vPLJpBEw0nhPP5jEstGYFGCk/rs:fit:888:1200:1/g:ce/aHR0cDovL2NkbjAx/LmNkbi5qdXN0amFy/ZWQuY29tL3dwLWNv/bnRlbnQvdXBsb2Fk/cy8yMDA3LzExL21h/ZC1tb25leS9tYWQt/bW9uZXktbW92aWUt/cG9zdGVyLTAxLmpw/Zw",
  "https://image.tmdb.org/t/p/original/3Y3wNbBozb84vB1djVNw1jVYJv0.jpg",
  "https://cdn.0123movies.com/upload/2017/10/movie_wheres-the-money-2017.jpg",
  "https://i.pinimg.com/originals/e2/2d/b6/e22db6b4ab7a799f1baeed78196a3414.jpg",
  "https://imgs.search.brave.com/NjFeWuudZYhnDqFchNca6QZy-8Oolxg3Jq2DyZ1c3jo/rs:fit:721:720:1/g:ce/aHR0cHM6Ly9pMC53/cC5jb20vd3d3LnNh/dmluZ3NhbmRzYW5n/cmlhLmNvbS93cC1j/b250ZW50L3VwbG9h/ZHMvMjAxOC8wOC9G/ZWF0dXJlLTc5LW1v/bmV5LWxlc3NvbnMt/ZnJvbS1kaXNuZXkt/bW92aWVzLmpwZz9m/aXQ9NzIxJTJDNzIw/JnNzbD0x",
  "https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/02F2C27D2535B829D85D9DD8FBCDA23A2BD7805A136AD060DBFCC315814FC4D3/scale?width=1200&aspectRatio=1.78&format=jpeg",
];

export default function InquiryForm() {
  const [projectInfo, setProjectInfo] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientContact, setClientContact] = useState("");
  const [inquirySubmission, setInquirySubmission] = useState<
    "success" | "error" | null
  >(null);

  const userInitiatedContact = useAtomValue(userInitiatedContactAtom);

  useEffect(() => {
    if (userInitiatedContact) {
      setTimeout(() => {
        document.getElementById("project-description")?.focus();
      }, 1000);
    }
  }, [userInitiatedContact]);

  const invokeSlackWebhook = () => {
    return fetch(
      "https://hooks.slack.com/services/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",
      {
        method: "POST",
        // Strangely unsupport by Slack.
        // headers: { "content-type": "application/json" },
        // mode: "cors",
        body: JSON.stringify({
          text: "Test Message 2",
          blocks: [
            {
              type: "image",
              image_url: funnyMoneyPics[random(0, funnyMoneyPics.length - 1)],
              alt_text: "inspiration",
            },
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "Yo. Somebody wants to give us money.",
                emoji: true,
              },
            },
            {
              type: "context",
              elements: [
                {
                  type: "plain_text",
                  text: DateTime.now().toLocaleString(DateTime.DATETIME_FULL),
                  emoji: true,
                },
              ],
            },
            {
              type: "divider",
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: `*Who:*\n${clientName}`,
                },
                {
                  type: "mrkdwn",
                  text: `*Contact:*\n${clientContact}`,
                },
              ],
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: projectInfo,
              },
            },
          ],
        }),
      }
    );
  };

  const formFieldCSS = css({
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 500,
    outline: "none",
    border: "none",
    padding: 15,
    opacity: 0.9,
    color: FFOrange,
    backgroundColor: "white",
    transition: "all .5s ease",
    [mediaQueries[3]]: {
      fontSize: 16,
    },
    boxSizing: "border-box",
    "::placeholder": {
      color: FFMidGray,
      textTransform: "uppercase",
    },
    ":focus": {
      color: "white",
      backgroundColor: FFOrangeMid,
      "::placeholder": {
        color: "white",
      },
    },
  });

  const renderForm = () => (
    <div>
      <textarea
        id="project-description"
        placeholder="What would you like to create?"
        value={projectInfo}
        onChange={(event) => setProjectInfo(event.target.value)}
        css={[
          formFieldCSS,
          {
            width: "100%",
            resize: "none",
            marginBottom: 5,
            [mediaQueries[2]]: {
              marginBottom: 10,
            },
          },
        ]}
        rows={5}
      />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          [mediaQueries[1]]: {
            flexDirection: "row",
          },
        }}>
        <input
          placeholder="YOUR NAME"
          value={clientName}
          onChange={(event) => setClientName(event.target.value)}
          css={[
            formFieldCSS,
            {
              marginBottom: 5,
              [mediaQueries[1]]: {
                maxWidth: 150,
                marginBottom: 0,
                marginRight: 10,
              },
              [mediaQueries[2]]: {
                maxWidth: 200,
              },
              [mediaQueries[3]]: {
                maxWidth: 250,
              },
            },
          ]}
        />
        <input
          placeholder="EMAIL OR CELL PHONE"
          value={clientContact}
          onChange={(event) => setClientContact(event.target.value)}
          css={[
            formFieldCSS,
            {
              flex: 1,
              marginBottom: 20,
              [mediaQueries[1]]: {
                marginBottom: 0,
                marginRight: 20,
              },
            },
          ]}
        />
        <BasicButton
          text="Submit"
          onClick={() => {
            invokeSlackWebhook()
              .then((response) => setInquirySubmission("success"))
              .catch((error) => setInquirySubmission("error"));
          }}
          disabled={
            !clientName.length || !clientContact.length || !projectInfo.length
          }
          colorSpec={{
            backgroundColor: FFOrangeMid,
            textColor: "white",
            hoverBackgroundColor: "white",
            hoverTextColor: FFOrangeMid,
          }}
          containerStyles={css({
            outline: "none",
            border: "none",
            padding: 15,
            fontFamily: "Inter",
            fontWeight: 600,
            boxSizing: "border-box",
            transition: "all .33s ease",
            "&:disabled": {
              color: "rgba(30, 30, 30, .6)",
              backgroundColor: FFMidGray,
              opacity: 0.5,
            },
          })}
        />
      </div>
    </div>
  );

  return (
    <div
      id="client-inquiry-form"
      css={{
        marginTop: 50,
        flex: 1,
        maxWidth: 700,
        [mediaQueries[2]]: {
          alignSelf: "flex-end",
          margin: 0,
        },
        [mediaQueries[3]]: {},
      }}>
      {inquirySubmission === null ? (
        renderForm()
      ) : inquirySubmission === "success" ? (
        <>
          <p
            css={{
              fontFamily: "Inter",
              color: "white",
              fontSize: 14,
              fontWeight: 500,
              margin: 0,
              marginTop: 15,
              [mediaQueries[1]]: {},
              [mediaQueries[3]]: { fontSize: 18 },
            }}>
            We are grateful for the opportunity to serve you.
          </p>
          <p
            css={{
              fontFamily: "Inter",
              color: "white",
              fontSize: 14,
              fontWeight: 300,
              margin: 0,
              marginTop: 15,
              [mediaQueries[1]]: {},
              [mediaQueries[3]]: { fontSize: 18 },
            }}>
            We'll be in touch shortly. Be on the lookout from our team. <br />
            We can't wait to learn more about you and your project.
          </p>
        </>
      ) : (
        <>
          <p
            css={{
              fontFamily: "Inter",
              color: "#F57E00",
              fontSize: 18,
              fontWeight: 700,
              margin: 0,
              marginTop: 15,
              [mediaQueries[1]]: {},
              [mediaQueries[3]]: { fontSize: 18 },
            }}>
            Oh man. It looks like the internet is having a hiccup.
          </p>
          <p
            css={{
              fontFamily: "Inter",
              color: "white",
              fontSize: 14,
              fontWeight: 300,
              margin: 0,
              marginTop: 15,
              [mediaQueries[1]]: {},
              [mediaQueries[3]]: { fontSize: 18 },
            }}>
            Your submission was great, but couldn't be delivered because of a
            problem with our messaging provider.
          </p>
          <p
            css={{
              fontFamily: "Inter",
              color: "white",
              fontSize: 14,
              fontWeight: 300,
              margin: 0,
              marginTop: 15,
              [mediaQueries[1]]: {},
              [mediaQueries[3]]: { fontSize: 18 },
            }}>
            You can reload the page and try again, or send us a quick email at{" "}
            <a
              css={{
                color: "#F57E00",
                fontWeight: 700,
              }}
              href="mailto:team@formfilms.com">
              team@formfilms.com
            </a>
          </p>
        </>
      )}
    </div>
  );
}
