/** @jsxImportSource @emotion/react */

import { CSSProperties } from "react";
import { css, SerializedStyles } from "@emotion/react";
import { mediaQueries } from "../../../styles/breakpoints";

type SectionHeaderProps = {
  textColor: CSSProperties["color"];
  primaryText: string;
  secondaryText: string;
  containerStyles?: SerializedStyles;
  primaryTextStyles?: SerializedStyles;
  secondaryTextStyles?: SerializedStyles;
};

export default function SectionHeader({
  textColor,
  primaryText,
  primaryTextStyles,
  secondaryText,
  secondaryTextStyles,
  containerStyles = css(),
}: SectionHeaderProps) {
  return (
    <h2
      css={[
        {
          fontFamily: "Inter",
          color: textColor,
          fontSize: 24,
          lineHeight: 1,
          textTransform: "uppercase",
          fontWeight: 700,
          margin: 0,
          flex: 1,

          [mediaQueries[0]]: {
            fontSize: 32,
            fontWeight: 900,
          },
          [mediaQueries[1]]: {
            fontSize: 42,
          },
          [mediaQueries[3]]: {
            fontSize: 48,
          },
        },
        containerStyles,
      ]}
    >
      <span
        css={[
          {
            fontSize: 18,
            fontWeight: 300,
            display: "block",
            [mediaQueries[1]]: {
              fontSize: 28,
            },
            [mediaQueries[3]]: {
              fontSize: 32,
            },
          },
          secondaryTextStyles,
        ]}
      >
        {secondaryText}
      </span>
      <p
        css={[
          {
            margin: 0,
            lineHeight: 0.9,
          },
          primaryTextStyles,
        ]}
      >
        {primaryText}
      </p>
    </h2>
  );
}
