/** @jsxImportSource @emotion/react */

import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { animated, useSpring, config } from "@react-spring/web";

import { menuOpenAtom } from "../data/menu";

export default function CropMarks() {
  const isMenuOpen = useRecoilValue(menuOpenAtom);

  const [styles, api] = useSpring(() => ({
    from: { opacity: 0.0, scale: 1 },
    to: { opacity: 0.4, scale: 1 },
    delay: 1000,
    config: {
      ...config.default,
      mass: 300,
      clamp: true,
    },
  }));

  useEffect(() => {
    if (isMenuOpen === undefined) return;

    !isMenuOpen
      ? api.start({
          from: { opacity: 0, scale: 1.25 },
          to: { opacity: 0.4, scale: 1 },
          config: { ...config.slow, mass: 1 },
        })
      : api.start({
          opacity: 0,
          scale: 1.25,
          config: { ...config.molasses, mass: 1 },
        });
  }, [isMenuOpen, api]);

  return (
    <animated.div
      style={{ ...styles }}
      css={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -2,
        overflow: "hidden",
        opacity: 0,
      }}
    >
      <div
        id="topRightCropMark"
        css={{
          position: "absolute",
          boxSizing: "border-box",
          width: 22,
          height: 22,
          right: 50,
          top: 60,
          borderStyle: "solid",
          borderColor: "white",
          borderWidth: 0,
          borderTopWidth: 6,
          borderRightWidth: 6,
          mixBlendMode: "color-dodge",
        }}
      />
      <div
        id="bottomRightCropMark"
        css={{
          position: "absolute",
          boxSizing: "border-box",
          width: 22,
          height: 22,
          right: 50,
          bottom: 60,
          borderStyle: "solid",
          borderColor: "white",
          borderWidth: 0,
          borderBottomWidth: 6,
          borderRightWidth: 6,
          mixBlendMode: "color-dodge",
        }}
      />
      <div
        id="bottomLeftCropMark"
        css={{
          position: "absolute",
          boxSizing: "border-box",
          width: 22,
          height: 22,
          left: 50,
          bottom: 60,
          borderStyle: "solid",
          borderColor: "white",
          borderWidth: 0,
          borderBottomWidth: 6,
          borderLeftWidth: 6,
          mixBlendMode: "color-dodge",
        }}
      />
    </animated.div>
  );
}
