/** @jsxImportSource @emotion/react */

import { useSpring, animated } from "@react-spring/web";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { currentFeatureAtom } from "../data/general";
import { menuOpenAtom } from "../data/menu";
import { vimeoVideoIDAtom } from "../data/state";

export default function FFLogo() {
  const [vimeoID, setVimeoID] = useRecoilState(vimeoVideoIDAtom);
  const setCurrentFeature = useSetRecoilState(currentFeatureAtom);
  const setMenuOpen = useSetRecoilState(menuOpenAtom);

  const [styles, animation] = useSpring(() => ({
    from: { scale: 1 },
    // delay: 1500,
    // config: { friction: 250 },
  }));

  useEffect(() => {
    vimeoID ? animation.start({ scale: 0.75 }) : animation.start({ scale: 1 });
  }, [vimeoID, animation]);

  return (
    <animated.button
      style={styles}
      css={{
        transformOrigin: "top left",
        display: "flex",
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        opacity: vimeoID ? 0.75 : 1,
        transition: "opacity .5s ease",
        "&:hover": {
          opacity: 1,
        },
      }}
      onClick={() => {
        window.location.href = "https://formfilms.com";
        // if (vimeoID) {
        //   setVimeoID(undefined);
        // } else {
        //   setCurrentFeature("Home");
        //   setMenuOpen(false);
        // }
      }}>
      <Logo width={40} height={40} />
      {vimeoID && (
        <p
          css={{
            fontFamily: "Gotham A, Gotham B",
            color: "white",
            marginLeft: 10,
            marginTop: 0,
            textTransform: "uppercase",
            fontWeight: 500,
            lineHeight: 1.3,
            fontSize: 15,
          }}>
          Exit <br />
          {vimeoID ? "Film" : "Interactive"}
        </p>
      )}
    </animated.button>
  );
}
