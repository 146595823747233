/** @jsxImportSource @emotion/react */

import useDimensions from "react-cool-dimensions";
import { DateTime } from "luxon";

import jurassicWorldBackground from "./jurassicWorldExplosion.jpg";

import { mediaQueries } from "../../../styles/breakpoints";
import SectionHeader from "./SectionHeader";
import { FFOffwhite } from "../../../styles/colors";
import { css } from "@emotion/react";

export default function TraditionalTrailers() {
  const { observe, height } = useDimensions({ useBorderBoxSize: true });
  const { observe: observeIFrame, width: iFrameWidth } = useDimensions({});

  return (
    <>
      <img
        alt="jurassic world background"
        src={jurassicWorldBackground}
        css={{
          minWidth: "100%",
          minHeight: height + 150,
          maxHeight: height + 150,
          filter: "blur(10px)",
          transform: "scale(1.25)",
          objectFit: "cover",
          position: "relative",
        }}
      />
      <div
        id="traditional-trailers-part-1-centering-div"
        ref={observe}
        css={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          top: 0,
          left: 0,
          right: 0,
          marginLeft: 35,
          marginRight: 35,
          marginTop: 125,

          [mediaQueries[1]]: {
            marginLeft: 125,
            marginRight: 125,
            marginTop: 125,
          },
        }}>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100%",
            [mediaQueries[2]]: {
              width: 1000,
            },
            [mediaQueries[3]]: {
              width: 1280,
            },
          }}>
          <h1
            css={{
              fontFamily: "Inter",
              color: "white",
              fontSize: 24,
              lineHeight: 1,
              fontWeight: 700,
              [mediaQueries[0]]: {
                fontSize: 32,
                fontWeight: 900,
              },
              [mediaQueries[1]]: {
                fontSize: 48,
                maxWidth: 725,
              },
              [mediaQueries[2]]: {
                fontSize: 56,
                maxWidth: 825,
              },
            }}>
            {/* GENERATE EXCITEMENT AND INSIGHTS FOR YOUR UPCOMING RELEASES */}
            EXCITE YOUR AUDIENCE & GATHER KEY INSIGHTS FOR UPCOMING RELEASES
          </h1>

          <iframe
            title="interactive-trailer-demo"
            src="https://video.eko.com/v/MwdvWG/embed"
            allow="autoplay; fullscreen"
            allowFullScreen
            id="eko-iframe"
            width={"100%"}
            height="100%"
            ref={observeIFrame}
            css={{
              border: "none",
              zIndex: 100,
              height: iFrameWidth / (16 / 9),
              width: "100%",
              [mediaQueries[2]]: {
                width: 1000,
              },
              [mediaQueries[3]]: {
                width: 1280,
              },
            }}
          />

          {/* <div
            id="eko-container"
            css={{
              position: "absolute",
              zIndex: 100,
              border: "none",
              height: iFrameWidth / (16 / 9),
              width: "100%",
              backgroundColor: "red",
              [mediaQueries[2]]: {
                width: 1000,
              },
              [mediaQueries[3]]: {
                width: 1280,
              },
            }}
            onClickCapture={(event) => {console.log("capture")}}
          /> */}
        </div>
      </div>

      <div
        id="traditional-trailers-part-2-centering-div"
        css={{
          paddingLeft: 35,
          paddingRight: 35,
          display: "flex",
          paddingBottom: 75,
          justifyContent: "center",
          paddingTop: 125,

          [mediaQueries[1]]: {
            paddingLeft: 125,
            paddingRight: 125,
            paddingTop: 175,
          },
        }}>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            [mediaQueries[2]]: {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: 1000,
            },
            [mediaQueries[3]]: {
              width: 1280,
            },
          }}>
          <SectionHeader
            textColor={FFOffwhite}
            primaryText="Don't excite your audience"
            secondaryText="Traditional Trailers"
            containerStyles={css({
              [mediaQueries[2]]: {
                maxWidth: 375,
              },
              [mediaQueries[3]]: {
                maxWidth: 450,
              },
            })}
            secondaryTextStyles={css({
              [mediaQueries[2]]: {
                maxWidth: 200,
              },
            })}
          />
          <div css={{ flex: 1 }}>
            <p
              css={{
                fontFamily: "Inter",
                color: "white",
                fontSize: 14,
                fontWeight: 300,
                margin: 0,
                marginTop: 25,
                [mediaQueries[2]]: {
                  marginLeft: 50,
                },
                [mediaQueries[3]]: {
                  fontSize: 18,
                },
              }}>
              It's {DateTime.now().year}, but movie trailers haven't changed
              since your grandma was young.{" "}
              <span css={{ fontWeight: 600 }}>
                Audiences are bored and ready for something new.
              </span>
            </p>
            <p
              css={{
                fontFamily: "Inter",
                color: "white",
                fontSize: 14,
                fontWeight: 300,
                margin: 0,

                marginTop: 15,
                [mediaQueries[2]]: {
                  marginLeft: 50,
                },
                [mediaQueries[3]]: {
                  fontSize: 18,
                },
              }}>
              Interactive content can help you engage with your potential
              audience - cutting through the constant noise of the internet.
            </p>
            <p
              css={{
                fontFamily: "Inter",
                color: "white",
                fontSize: 14,
                fontWeight: 300,
                margin: 0,

                marginTop: 15,
                [mediaQueries[2]]: {
                  marginLeft: 50,
                },
                [mediaQueries[3]]: {
                  fontSize: 18,
                },
              }}>
              Every aspect of the experience is hand-coded and therefore
              completely customizable, allowing each viewer to explore an
              "upcoming release" according to their own interests.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
