/** @jsxImportSource @emotion/react */

import { useRecoilValue } from "recoil";
import "core-js";

import FilmGrainEffect from "./components/FilmGrainEffect";
import Home from "./components/Home";
import Linear from "./components/Linear";
import About from "./components/About";
import VimeoPlayer from "./components/Linear/VimeoPlayer";
import Menu from "./components/Menu";
import TopGlobalNav from "./components/TopGlobalNav";
import Matte from "./components/Matte";

import { currentFeatureAtom } from "./data/general";
import { Route, Routes, useNavigate } from "react-router-dom";
import { InteractiveTrailer } from "./components/features";
import { useEffect } from "react";
import InteractiveEmbedTest from "./components/features/InteractiveEmbedTest";
import EducationalTours from "./components/features/EducationalTours";

function App() {
  const currentFeature = useRecoilValue(currentFeatureAtom);
  const navigate = useNavigate();

  /** Special Features Redirection */
  useEffect(() => {
    if (window.location.hostname.toLowerCase() === "trailer.formfilms.com") {
      navigate("/interactive-trailer");
    }

    if (
      window.location.hostname.toLowerCase() ===
      "campusexperience.formfilms.com"
    ) {
      navigate("/educational-tours");
    }
  }, [navigate]);

  return (
    <div>
      <TopGlobalNav />
      {/* <FilmGrainEffect /> */}
      <Matte />

      <Routes>
        <Route
          path="/"
          element={
            <>
              {/* <VimeoPlayer /> */}
              <Home />
              {/* <Menu /> */}
              {/* <Linear /> */}
              {/* {currentFeature === "About" && <About />}  */}
            </>
          }
        />
        <Route path="interactive-trailer" element={<InteractiveTrailer />} />
        <Route path="educational-tours" element={<EducationalTours />} />
        <Route
          path="interactive-embed-test"
          element={<InteractiveEmbedTest />}
        />
      </Routes>
    </div>
  );
}

export default App;
