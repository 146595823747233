/** @jsxImportSource @emotion/react */

import useDimensions from "react-cool-dimensions";
import { DateTime } from "luxon";

import gacField from "./gacFieldSide.jpg";

import { mediaQueries } from "../../../styles/breakpoints";

import { FFOffwhite, FFOrangeMid } from "../../../styles/colors";
import { css } from "@emotion/react";
import SectionHeader from "../InteractiveTrailer/SectionHeader";
import { useAtom, useSetAtom } from "jotai";
import { userInitiatedContactAtom } from "../../../data/state";
import BasicButton from "../../widgets/BasicButton";

export default function Intro() {
  const { observe, height } = useDimensions({ useBorderBoxSize: true });
  const { observe: observeIFrame, width: iFrameWidth } = useDimensions({});

  const setUserInitiatedContact = useSetAtom(userInitiatedContactAtom);

  return (
    <>
      <img
        alt="featureBackground"
        src={gacField}
        css={{
          minWidth: "100%",
          minHeight: height + 150,
          maxHeight: height + 150,
          filter: "blur(10px) brightness(.90)",
          transform: "scale(1.25)",
          objectFit: "cover",
          position: "relative",
        }}
      />
      <div
        id="traditional-trailers-part-1-centering-div"
        ref={observe}
        css={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          top: 0,
          left: 0,
          right: 0,
          marginLeft: 35,
          marginRight: 35,
          marginTop: 125,
          [mediaQueries[1]]: {
            marginLeft: 125,
            marginRight: 125,
          },
        }}>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100%",
            [mediaQueries[2]]: {
              maxWidth: 1280,
            },
            [mediaQueries[3]]: {
              maxWidth: 1600,
            },
          }}>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}>
            <h1
              css={{
                fontFamily: "Inter",
                color: "white",
                fontSize: 24,
                lineHeight: 1,
                fontWeight: 700,
                marginBottom: 0,
                textTransform: "uppercase",
                [mediaQueries[0]]: {
                  fontSize: 32,
                  fontWeight: 900,
                },
                [mediaQueries[1]]: {
                  fontSize: 48,
                  maxWidth: 725,
                },
                [mediaQueries[2]]: {
                  fontSize: 56,
                  maxWidth: 825,
                },
              }}>
              A campus experience unlike anything you have seen before
            </h1>
            <BasicButton
              text="Tell us about your school"
              onClick={() => setUserInitiatedContact(true)}
              colorSpec={{
                textColor: "white",
                hoverTextColor: FFOrangeMid,
                backgroundColor: FFOrangeMid,
                hoverBackgroundColor: "white",
              }}
              containerStyles={css({
                marginTop: 10,
                fontSize: 14,
                borderRadius: 5,
                padding: 15,
                display: "none",
                [mediaQueries[2]]: { display: "initial" },
              })}
            />
          </div>
          <iframe
            title="interactive-trailer-demo"
            src="https://gac-drone-tour.vercel.app/"
            allow="autoplay; fullscreen"
            allowFullScreen
            ref={observeIFrame}
            css={{
              aspectRatio: "16/9",
              border: "none",
              zIndex: 100,
              height: iFrameWidth / (16 / 9),
              width: "100%",
            }}
          />
          <BasicButton
            text="Tell us about your school"
            onClick={() => setUserInitiatedContact(true)}
            colorSpec={{
              textColor: "white",
              hoverTextColor: FFOrangeMid,
              backgroundColor: FFOrangeMid,
              hoverBackgroundColor: "white",
            }}
            containerStyles={css({
              marginTop: 10,
              fontSize: 14,
              borderRadius: 5,
              padding: 15,
              [mediaQueries[2]]: { display: "none" },
            })}
          />
        </div>
      </div>

      <div
        id="traditional-trailers-part-2-centering-div"
        css={{
          paddingLeft: 35,
          paddingRight: 35,
          display: "flex",
          paddingBottom: 75,
          justifyContent: "center",
          paddingTop: 125,

          [mediaQueries[1]]: {
            paddingLeft: 125,
            paddingRight: 125,
            paddingTop: 175,
          },
        }}>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            [mediaQueries[2]]: {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: 1000,
              marginTop: 25,
            },
            [mediaQueries[3]]: {
              marginTop: 50,
              maxWidth: 1280,
            },
          }}>
          <SectionHeader
            textColor={FFOffwhite}
            primaryText="No longer engage students and parents"
            secondaryText="Traditional Online Campus Tours"
            containerStyles={css({
              flex: 1,
              [mediaQueries[2]]: {
                maxWidth: 400,
              },
              [mediaQueries[3]]: {
                maxWidth: 500,
              },
            })}
            secondaryTextStyles={css({})}
          />
          <div
            css={{
              flex: 1,
              margin: 0,
              marginTop: 20,
              [mediaQueries[2]]: {
                marginLeft: 50,
                marginTop: 0,
              },
              [mediaQueries[3]]: {
                marginLeft: 75,
              },
            }}>
            <p
              css={{
                fontFamily: "Inter",
                color: "white",
                fontSize: 14,
                fontWeight: 300,
                margin: 0,
                [mediaQueries[3]]: {
                  fontSize: 18,
                },
              }}>
              It's {DateTime.now().year} and schools have been doing video and
              photo-based campus tours for over a decade now.
              <span css={{ fontWeight: 600 }}>
                {" "}
                The problem is, they all function and feel almost identical.
              </span>
            </p>
            <p
              css={{
                fontFamily: "Inter",
                color: "white",
                fontSize: 14,
                fontWeight: 300,
                margin: 0,
                marginTop: 15,

                [mediaQueries[3]]: {
                  fontSize: 18,
                },
              }}>
              They're either a linear experience with no choice or variation -
              or a hacked-together collection of pieces that miss the heart of
              your institution and the difference you make in students' lives.
            </p>
            <p
              css={{
                fontFamily: "Inter",
                color: "white",
                fontSize: 14,
                fontWeight: 300,
                margin: 0,
                marginTop: 15,

                [mediaQueries[3]]: {
                  fontSize: 18,
                },
              }}>
              We're changing that by helping schools create expectation
              shattering tours that respond to the choices and interests of
              prospective families - in real-time.
            </p>
            <p
              css={{
                fontFamily: "Inter",
                color: "white",
                fontSize: 14,
                fontWeight: 600,
                margin: 0,
                marginTop: 15,

                [mediaQueries[3]]: {
                  fontSize: 18,
                },
              }}>
              Each family gets a tour that is unique to them - so you can stand
              out from the noise and meet them exactly where they are.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
